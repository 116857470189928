<template>
  <UiSidePanel v-model="vModel" size="medium" glued>
    <template #default>
      <div class="flex h-full flex-col">
        <!-- Header -->
        <div
          class="relative border-b border-solid border-black-10 px-8 pb-4 pt-8 transition-height duration-200"
          :class="[
            {
              'z-[1] border-b border-solid border-black-10 shadow': userScrolled,
            },
            userScrolled ? 'h-[76px]' : 'h-36',
          ]"
        >
          <Transition name="fade" mode="out-in">
            <!-- Initial state -->
            <div v-if="!userScrolled" class="flex flex-col gap-6">
              <div class="flex flex-row items-center justify-between">
                <div class="flex flex-row items-center gap-2">
                  <h3>Notifications</h3>
                  <div
                    v-if="unreadNotificationsCount > 0"
                    class="text-caption flex h-6 w-7 items-center justify-center rounded-full bg-primary-100 p-[6px] text-white"
                  >
                    {{ unreadNotificationsCount }}
                  </div>
                </div>
              </div>

              <div class="flex flex-row items-center justify-between">
                <div class="w-[250px]">
                  <UiInputSelect
                    v-model="filters.type_ids"
                    :items="notificationTypes"
                    placeholder="All notifications"
                    name="activities_statuses"
                    multiple
                    @update:model-value="getNotifications()"
                  ></UiInputSelect>
                </div>
                <div class="flex flex-row items-center gap-2">
                  <div class="text-subhead-4 text-black-60">Only show unread</div>
                  <UiInputTogglePrimary
                    v-model="onlyShowUnread"
                    name="enabled"
                    @update:model-value="getNotifications()"
                  />
                </div>
              </div>
            </div>
            <!-- After scroll -->
            <div v-else class="relative flex h-7 flex-row items-center justify-between">
              <span v-if="currentHeaderDay" class="text-table-header w-1/3 text-black-40">
                {{
                  isToday(new Date(currentHeaderDay))
                    ? 'Today'
                    : isYesterday(new Date(currentHeaderDay))
                    ? 'Yesterday'
                    : useHumanizeDate(new Date(currentHeaderDay))
                }}</span
              >
              <UiTooltip name="notification-types">
                <template #activator>
                  <UiTagPrimary id="notification_types" right-icon="" class="w-1/3">
                    <template #default>
                      <div class="text-subhead-3 !text-black-100">
                        {{
                          !filters.type_ids?.length
                            ? 'All notifications'
                            : filters.type_ids?.length > 1
                            ? `${notificationTypes.find((nt) => filters.type_ids.includes(nt.value))?.text} +${
                                filters.type_ids?.length - 1
                              }`
                            : `${notificationTypes.find((nt) => filters.type_ids.includes(nt.value))?.text}`
                        }}
                      </div>
                    </template>
                  </UiTagPrimary>
                </template>
                <template #content>
                  <span class="text-body-2"> Notification type: </span><br />
                  <span class="text-subhead-4">
                    {{
                      !filters.type_ids?.length
                        ? 'All notifications'
                        : notificationTypes
                            .filter((nt) => filters.type_ids.includes(nt.value))
                            .map((t) => t.text)
                            .join('; ')
                    }}
                  </span>
                </template>
              </UiTooltip>

              <div class="flex w-1/3 justify-end">
                <UiButtonGhost
                  id="mark_as_read"
                  class="text-subhead-4"
                  :class="{ invisible: unreadNotificationsCount == 0 }"
                  @click="markAllAsRead"
                >
                  Mark all as read
                </UiButtonGhost>
              </div>
            </div>
          </Transition>

          <UiLoader v-if="loading" class="absolute bottom-0 left-0" />
        </div>
        <!-- Notifications  -->
        <div ref="notificationsDiv" class="h-full overflow-y-auto" @scroll="checkScroll">
          <div class="flex h-full flex-col px-8 py-4">
            <div
              v-if="!notifications.length && !loading"
              class="flex flex-col items-center justify-center gap-4 rounded-2xl bg-primary-05 py-16"
            >
              <UiIcon name="no-notifications" class="!h-16 !w-16 text-primary-20" />
              <span class="text-subhead-4">No notifications yet</span>
            </div>
            <div v-else-if="notificationsByDay" class="flex flex-col gap-8 pb-4">
              <div
                v-for="([day, dayNotifications], index) in Object.entries(notificationsByDay)"
                :id="filters.type_ids.length ? `notifications-${filters.type_ids}` : 'notifications'"
                :key="day"
                class="flex flex-col gap-4"
              >
                <div class="flex flex-row items-center justify-between">
                  <span class="text-table-header text-black-40">
                    {{
                      isToday(new Date(day))
                        ? 'Today'
                        : isYesterday(new Date(day))
                        ? 'Yesterday'
                        : useHumanizeDate(new Date(day))
                    }}
                  </span>
                  <UiButtonGhost
                    v-if="!index && unreadNotificationsCount > 0"
                    id="mark_as_read"
                    class="text-subhead-4 cursor-pointer text-primary-100"
                    @click="markAllAsRead"
                  >
                    Mark all as read
                  </UiButtonGhost>
                </div>
                <NotificationsItem
                  v-for="notification in (dayNotifications as InternalNotification[])"
                  :id="`notification-${notification.id}-type-${notification.type.id}`"
                  :key="notification.id"
                  :model-value="notification"
                  @update:model-value="updateMarkAsRead"
                  @open-activity="showActivitySidePanel"
                >
                  {{ notification }}
                </NotificationsItem>
              </div>
              <UiButtonGhost
                v-if="notifications.length < paginationData?.total"
                id="see_more"
                class="mx-auto mb-[-52px]"
                :disabled="loading"
                @click="getMoreNotifications"
                >See more
              </UiButtonGhost>
              <Transition name="fade">
                <UiButtonBase
                  v-if="userScrolled"
                  id="scroll-up"
                  icon
                  class="sticky bottom-8 right-8 ml-auto"
                  @click="scrollToTop"
                >
                  <UiIcon name="arrow-big-up"></UiIcon>
                </UiButtonBase>
              </Transition>
              <div
                v-if="notifications.length === paginationData?.total && !loading"
                class="flex flex-col items-center justify-center gap-4 rounded-2xl bg-primary-05 py-6"
                :class="userScrolled ? 'mt-[-52px]' : ''"
              >
                <UiIcon name="flag" class="!h-16 !w-16 text-primary-20" />
                <span class="text-subhead-4 mx-auto w-6/12 text-center"
                  >That's all your notifications for the last 30 days.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </UiSidePanel>
</template>

<script setup lang="ts">
import { startOfDay, isToday, isYesterday } from 'date-fns'
import { POPUPS, SIDE_PANELS } from '@/components/dynamic/maps'
import type { InputItem, NotificationsFilters, InternalNotification, Activity } from '@/types'
import { useUiStore } from '~/store/ui'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const vModel = useVModel(props, 'modelValue', emits)

const loading = ref(false)
const notifications = ref<InternalNotification[]>([])
const notificationsByDay = ref()
const unreadNotificationsCount = ref(0)
const notificationTypes = ref<InputItem[]>([])
const paginationData = ref(useCreatePagination())

const contentOverflows = ref(false)
const userScrolled = ref(false)
const notificationsDiv = ref<HTMLElement>()
const currentHeaderDay = ref('')
const onlyShowUnread = ref(false)

const generateDefaultFilters = () => ({
  type_ids: [],
  is_read: undefined,
})

const filters = ref(
  useCreateFilters({
    ...generateDefaultFilters(),
    ...uiStore.getNotificationsFilters,
  }) as NotificationsFilters
)

onMounted(async () => {
  await Promise.all([getNotificationTypes(), getNotifications(), getUnreadNotificationsCount()])
  nextTick(() => {
    checkScroll()
  })
})

onNuxtReady(() => {
  nextTick(() => {
    checkScroll()
  })
})

const getNotifications = async () => {
  loading.value = true
  paginationData.value.current_page = 1
  filters.value.is_read = onlyShowUnread.value ? false : undefined
  const params = useParseFilters(filters.value, paginationData.value, [], false)
  // If "all notifications" was selected, just get that value
  if (params.type_ids?.length && Array.isArray(params.type_ids[0])) {
    params.type_ids = params.type_ids[0]
  }
  try {
    const response = await useGetNotifications(params)
    notifications.value = response.data
    paginationData.value = response.pagination
    groupNotificationsByDay()
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}

const getMoreNotifications = async () => {
  loading.value = true
  paginationData.value.current_page++
  filters.value.is_read = onlyShowUnread.value ? false : undefined
  const params = useParseFilters(filters.value, paginationData.value, [], false)
  // If "all notifications" was selected, just get that value
  if (params.type_ids?.length && Array.isArray(params.type_ids[0])) {
    params.type_ids = params.type_ids[0]
  }
  try {
    const response = await useGetNotifications(params)
    notifications.value = [...notifications.value, ...response.data]
    paginationData.value = response.pagination
    groupNotificationsByDay()
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}

const groupNotificationsByDay = () => {
  notificationsByDay.value = notifications.value.reduce(function (acc, curr) {
    const day = startOfDay(new Date(curr.created_at))
    ;(acc[day] = acc[day] || []).push({ ...curr, isUnread: !curr.read_at })
    return acc
  }, {})
  currentHeaderDay.value = Object.keys(notificationsByDay.value)[0]
  setTimeout(() => {
    Object.keys(notificationsByDay.value).forEach((k, index) => {
      const element = document.getElementById(k)
      if (element) createObserver(element, index)
    })
  })
}

const getUnreadNotificationsCount = async () => {
  try {
    const response = await useGetUnreadNotificationsCount()
    unreadNotificationsCount.value = response.data.count
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getNotificationTypes = async () => {
  const response = await useNotificationTypes()
  notificationTypes.value = response.map((i) => ({ value: i.id, text: i.name }))
}

const showActivitySidePanel = (activity: Activity) => {
  uiStore.cleanSidePanel()
  setTimeout(() => {
    uiStore.showSidePanel(
      SIDE_PANELS.ACTIVITY_PREVIEW,
      { activity },
      {
        edit: (activity: Activity) => showEditActivitySidePanel(activity),
        delete: (activity: Activity) => showDeleteActivityPopup(activity),
      }
    )
  }, 200)
}

const showEditActivitySidePanel = (activity: Activity) => {
  uiStore.cleanSidePanel()
  setTimeout(() => {
    uiStore.showSidePanel(SIDE_PANELS.ACTIVITY_CREATE_EDIT, { activityToEdit: activity })
  }, 200)
}

const showDeleteActivityPopup = (activity: Activity) => {
  uiStore.cleanSidePanel()
  uiStore.showPopup(
    POPUPS.ACTIVITY_DELETE,
    { activity },
    {
      cancel: () => showActivitySidePanel(activity),
      input: () => {
        nextTick(() => {
          uiStore.showSidePanel(SIDE_PANELS.NOTIFICATIONS)
        })
      },
    }
  )
}

const markAllAsRead = async () => {
  loading.value = true
  try {
    await useSetAllNotificationRead()
    await getNotifications()
    unreadNotificationsCount.value = 0
    uiStore.setHasNewNotifications(!!unreadNotificationsCount.value)
    uiStore.showSnackBanner('All notifications marked as read', 'success')
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}

const updateMarkAsRead = async (notification: InternalNotification) => {
  if (notification.isUnread) {
    unreadNotificationsCount.value++
    try {
      await useSetNotificationUnread(notification.id)
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    }
  } else {
    unreadNotificationsCount.value--
    try {
      await Promise.all([useSetNotificationRead(notification.id), getNotifications()])
    } catch (error: any) {
      uiStore.showSnackBanner(error.message, 'error')
    }
  }
  uiStore.setHasNewNotifications(!!unreadNotificationsCount.value)
}

const scrollToTop = () => {
  notificationsDiv.value?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
}

const checkScroll = () => {
  contentOverflows.value = Number(notificationsDiv?.value?.scrollHeight) > Number(notificationsDiv?.value?.offsetHeight)

  if (contentOverflows.value) userScrolled.value = Number(notificationsDiv.value?.scrollTop) > 0
}

type Section = {
  id: string
  isVisible: boolean
  isIntersecting: boolean
}
const observers = ref<Section[]>([])
const createObserver = (target: HTMLElement) => {
  const options = {
    root: null,
    threshold: 0.1,
  }
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const observer = observers.value.find((o) => o.id === entry.target.id)
      if (observer) {
        observer.isVisible = entry.isVisible
        observer.isIntersecting = entry.isIntersecting
      }
    })
    currentHeaderDay.value = observers.value.find((o) => !o.isVisible && o.isIntersecting)?.id as string
  }, options)
  observer.observe(target)
  observers.value.push({
    id: target.id,
    isVisible: true,
    isIntersecting: false,
  })
}
</script>

<style scoped></style>
